import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";
import logo from "../assets/sd.png";
import { getModeInfo, getServerVersion } from "../api/rest";
import { dlog } from "../sdlib/sdutil";
import { setUserData } from "../modules/user";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Logo from "../assets/logo.png";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    width: "80px",
    height: "80px",
    borderRadius: "20px",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  version: {
    color: "#bebebe",
    marginTop: "40px",
    textAlign: "center",
  },
}));

const SiteTitle = styled.div`
  font-size: 40px;
  text-align: center;
  font-weight: bold;
`;

const LogoImage = styled.img`
  margin-top: 80px;
  width: 100%;
  object-fit: contain;
  height: min-content;
`;

function Login() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const history = useHistory();
  const [data, setData] = useState({
    id: "",
    password: "",
    error: false,
  });
  const { id, password, error } = data;

  const [version, setVersion] = useState({
    server: "",
    front: process.env.REACT_APP_VERSION_CODE,
  });
  const { server, front } = version;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  useEffect(() => {
    getServerVersion().then((r) => {
      const data = r.data.version.version;
      const server_version = `${data.build}.${data.major}.${data.minor}.${data.revision}`;
      setVersion({
        ...version,
        server: server_version,
      });
    });

    return () => {};
  }, []);

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setData({
      ...data,
      error: false,
    });

    getModeInfo({
      id,
      password,
    })
      .then(() => {
        dispatch(
          setUserData({
            id,
            password,
          })
        );
        history.replace("/");
      })
      .catch((err) => {
        dlog(err);
        setData({
          ...data,
          error: true,
        });
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <LogoImage src={Logo} />
      <SiteTitle>Weather Service</SiteTitle>
      <div className={classes.paper}>
        <img className={classes.avatar} src={logo} />
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="id"
            label="Id"
            name="id"
            autoComplete="id"
            autoFocus
            onChange={onChange}
            value={id}
            error={error}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={onChange}
            value={password}
            error={error}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={onSubmit}
          >
            Sign In
          </Button>
        </form>
        <div className={classes.version}>
          Version
          <br />
          GUI : {front}
          <br />
          SERVER : {server}
        </div>
      </div>
    </Container>
  );
}

export default Login;
