import axios from "axios";

/**
 * API 기본 주소
 * 디버그모드인 경우에는 local 서버를 바라보게끔 설정
 */
// const url =
//   process.env.NODE_ENV === "development" ? "http://221.147.43.71:7005" : "";
const url =
  process.env.NODE_ENV === "development" ? "http://14.39.176.140:7005" : "";

export function getServerVersion() {
  return axios.post(`${url}/Version`);
}

export function getModeInfo(user: { id: string; password: string }) {
  return axios.get(`${url}/Weather/get_mode_info`, {
    auth: {
      username: user.id,
      password: user.password,
    },
  });
}

export function getRegionInfo(user: { id: string; password: string }) {
  return axios.get(`${url}/Weather/get_region_info`, {
    auth: {
      username: user.id,
      password: user.password,
    },
  });
}

export function getWeatherInfo(
  mode: string,
  region: string,
  user: { id: string; password: string }
) {
  let formData = new FormData();
  formData.append("mode", mode);
  formData.append("region", region);
  return axios.post(`${url}/Weather`, formData, {
    auth: {
      username: user.id,
      password: user.password,
    },
  });
}
