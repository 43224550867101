import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { dlog } from "../sdlib/sdutil";
import moment from "moment";

type WeatherTableType = {
  data: WeatherType[];
};

export type WeatherType = {
  condition_code: number;
  description: string;
  imfine_description: string;
  temprature: number;
  update_time: string;
  WeatherIconUrl: string;
};

interface Column {
  id:
    | "condition_code"
    | "description"
    | "imfine_description"
    | "temprature"
    | "update_time"
    | "WeatherIconUrl";
  label: string;
}

const columns: Column[] = [
  {
    id: "WeatherIconUrl",
    label: "Image",
  },
  {
    id: "update_time",
    label: "Update Time",
  },
  {
    id: "condition_code",
    label: "Condition Code",
  },
  { id: "description", label: "Description" },
  {
    id: "imfine_description",
    label: "Imfine Description",
  },
  {
    id: "temprature",
    label: "Temprature",
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginBottom: "30px",
  },
  container: {
    maxHeight: 440,
  },
});

function WeatherTable({ data }: WeatherTableType) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  if (data === undefined) return <></>;
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align="center">
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <TableCell align="center">
                      <img src={item.WeatherIconUrl} />
                    </TableCell>
                    <TableCell align="center">
                      {moment(item.update_time).format("YYYY-MM-DD hh:mm A")}
                    </TableCell>
                    <TableCell align="center">{item.condition_code}</TableCell>
                    <TableCell align="center">{item.description}</TableCell>
                    <TableCell align="center">
                      {item.imfine_description}
                    </TableCell>
                    <TableCell align="center">{item.temprature}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default WeatherTable;
