import React, { RefObject, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { styled as mstyled } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { getModeInfo, getRegionInfo, getWeatherInfo } from "../api/rest";
import styled from "styled-components";
import { Button, IconButton, Tooltip } from "@material-ui/core";
import { Code, SendSharp } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { RootState } from "../modules";
import { UserDataType } from "../modules/user";
import ReactJson from "react-json-view";
import ReactMarkdown from "react-markdown";
import "github-markdown-css";
import WeatherTable from "../components/WeatherTable";

const Root = styled.div`
  padding: 40px;
`;

const HorizontalLine = styled.div`
  display: flex;
`;

const CustomForm = mstyled(FormControl)({
  maxWidth: "400px",
  flex: 1,
  margin: "5px 20px",
});

const RunButton = mstyled(Button)({
  flex: 1,
  fontSize: "12px",
  margin: "10px 0px",
  marginLeft: "50px",
  maxWidth: "200px",
});

const APIButton = styled.div`
  width: fit-content;
  margin-left: 10px;
  margin-bottom: 40px;
`;

function Main() {
  const history = useHistory();
  const modeSelect: RefObject<HTMLSelectElement> = useRef(null);
  const regionSelect: RefObject<HTMLSelectElement> = useRef(null);
  const user = useSelector((state: RootState) => state.user as UserDataType);

  const [items, setItems] = useState({
    mode_list: [] as string[],
    region_list: [] as string[],
    mode: "",
    region: "",
  });
  const { mode_list, region_list, mode, region } = items;

  const initialInfo = {
    show_docs: false,
    result: {},
    docs: "",
  };
  const [info, setInfo] = useState(initialInfo);
  const { show_docs, result, docs } = info;

  const onChange = (e: React.ChangeEvent<any>) => {
    const target = e.currentTarget as HTMLSelectElement;
    setItems({
      ...items,
      [target.name]: target.value,
    });
  };

  const onRunQuery = () => {
    setInfo({
      ...info,
      result: {},
    });

    if (mode !== "" && region !== "") {
      getWeatherInfo(mode, region, user).then((response) => {
        const data = response.data;
        setInfo({
          ...info,
          result: data,
        });
      });
    }
  };

  const onToggleDocs = () => {
    const isShow = show_docs;
    setInfo({
      result,
      docs,
      show_docs: !isShow,
    });
  };

  useEffect(() => {
    fetch(`${document.URL}sd_imweather_document.md`)
      .then((response) => response.text())
      .then((text) => {
        setInfo({
          ...info,
          docs: text,
        });
      });

    return () => {
      setInfo(initialInfo);
    };
  }, []);

  useEffect(() => {
    if (user === null) {
      history.replace("/login");
      return;
    }

    getModeInfo(user).then((response) => {
      const mode_list = response.data.data;
      getRegionInfo(user).then((r) => {
        const region_list = r.data.data;
        setItems({
          ...items,
          mode_list: [...mode_list],
          region_list: [...region_list],
        });
      });
    });
    return () => {
      setInfo(initialInfo);
    };
  }, [user]);

  if (user === null) return <></>;

  return (
    <Root>
      <HorizontalLine>
        <CustomForm>
          <InputLabel htmlFor="mode-select">Mode</InputLabel>
          <Select
            ref={modeSelect}
            native
            defaultValue=""
            id="mode-select"
            name="mode"
            onChange={onChange}
          >
            <option aria-label="None" value="" />
            {mode_list.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </Select>
        </CustomForm>
        <CustomForm>
          <InputLabel htmlFor="region-select">Region</InputLabel>
          <Select
            ref={regionSelect}
            native
            defaultValue=""
            id="region-select"
            name="region"
            onChange={onChange}
          >
            <option aria-label="None" value="" />
            {region_list.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </Select>
        </CustomForm>

        <RunButton
          variant="outlined"
          color="primary"
          startIcon={<SendSharp />}
          onClick={onRunQuery}
        >
          Run Query
        </RunButton>
      </HorizontalLine>

      <APIButton>
        <Tooltip title="Show API Document">
          <IconButton onClick={onToggleDocs}>
            <Code />
          </IconButton>
        </Tooltip>
      </APIButton>

      {show_docs && (
        <div className="markdown-body" style={{ marginBottom: "50px" }}>
          <ReactMarkdown source={docs} />
        </div>
      )}

      <ReactMarkdown source={"# Result"} />
      {/* <SubTitle>Result</SubTitle> */}

      <WeatherTable data={(result as any).data} />

      <ReactJson
        src={result}
        style={{
          margin: "10px",
        }}
      />
    </Root>
  );
}

export default Main;
