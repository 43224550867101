import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Login from "./pages/Login";
import Main from "./pages/Main";

function App() {
  return (
    <Switch>
      <Route path="/" component={Main} exact />
      <Route path="/login" component={Login} />
      <Redirect to="/" />
    </Switch>
  );
}

export default App;
