const SET_USER_DATA = "user/SET_USER_DATA" as const;

export const setUserData = (item: UserDataType) => ({
  type: SET_USER_DATA,
  data: item,
});

export type UserDataType = {
  id: string;
  password: string;
};

const initialState: UserDataType | null = null;

type UserAction = ReturnType<typeof setUserData>;

export default function user(state = initialState, action: UserAction) {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...action.data,
      };
    default:
      return state;
  }
}
